* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.App {
  padding: 20px;
  background-color: pink;
}
table {
  border-collapse: separate;
  border-spacing: 0 1em;
  overflow-x: none;
}
